import {
  EmailAuthProvider,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updateEmail,
  updatePassword,
} from "firebase/auth";
import React, { useContext, useState, useEffect, createContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { auth } from "../config/firebase";
import swal from "sweetalert";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const history = useHistory();
  const signup = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };
  const login = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const logout = () => {
    localStorage.removeItem("currentuser");
    localStorage.removeItem("switchedcustomer");
    return signOut(auth);
  };

  const resetPassword = (email) => {
    return sendPasswordResetEmail(auth, email);
  };
  const updateUserEmail = (email) => {
    return updateEmail(auth.currentUser, email);
  };
  const reauthenticate = (currentEmail, currentPassword) => {
    const credential = EmailAuthProvider.credential(
      currentEmail,
      currentPassword
    );
    return reauthenticateWithCredential(auth.currentUser, credential);
  };
  const changePassword = async (currentEmail, currentPassword, newPassword) => {
    await reauthenticate(currentEmail, currentPassword)
      .then(() => {
        var user = auth.currentUser;

        updatePassword(user, newPassword)
          .then(() => {
            return "Password updated!";
          })
          .catch((error) => {
            throw new Error("Error updating password");
          });
      })
      .catch((error) => {
        throw new Error("Error reauthenticating");
      });
  };

  const updateUserPassword = (password) => {
    return updatePassword(auth.currentUser, password);
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      // Check the current path to avoid polling on certain routes
      if (
        location.pathname === "/signin" ||
        location.pathname === "/signup" ||
        location.pathname === "/ticket-reply" ||
        location.pathname === "/datenschutzerklarung" ||
        location.pathname === "/__/auth/action" ||
        location.pathname === "/forgot-password" ||
        !auth.currentUser
      ) {
        return;
      }

      try {
        await auth.currentUser.getIdToken(true);
      } catch (error) {
        if (error.code === "auth/user-token-expired") {
          console.log("Token expired. Logging out...");

          swal({
            title: "ACHTUNG",
            icon: "info",
            text: "Sie werden von der APP abgemeldet. Bitte nutzen Sie Ihre neuen Zugangsdaten um sich wieder anzumelden.",
            button: "Verstanden",
          }).then(async () => {
            await logout();
            history.push("/signin");
          });
        } else {
          console.error("Error during token refresh:", error);
        }
      }
    }, 3000); // Run every 3 seconds

    return () => {
      clearInterval(interval);
    };
  }, [location, auth.currentUser]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, []);
  return (
    <AuthContext.Provider
      value={{
        currentUser,
        login,
        signup,
        logout,
        resetPassword,
        updateUserEmail,
        updateUserPassword,
        changePassword,
        reauthenticate,
      }}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
};
export const useAuth = () => {
  const authContext = useContext(AuthContext);
  return authContext;
};

export default AuthProvider;
