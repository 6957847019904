import React, { useRef, useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import { Link, useLocation, useHistory } from "react-router-dom";
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import Alert from '@mui/material/Alert';
import userIcon from '../../assets/Icons/user-icon.svg'
import passwordIcon from '../../assets/Icons/password-icon.svg'
import infoIcon from '../../assets/Icons/info-icon.svg'
import { Col, Row } from "react-bootstrap";
import logoXcite from "../../assets/Icons/black-logo.svg"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { InputAdornment } from '@mui/material';
import { userService } from "../../_services/user.service";
import { companyService } from "../../_services/company.service";
import swal from "sweetalert";
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Login() {
  const location = useLocation();
  const history = useHistory();
  const initialValues = { email: "", password: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false)
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const redirectPath = location.state?.path || "/";
  const [showPassword, setShowPassword] = useState(false);
  //function to show password decrypted
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    // Check if the signupSuccess state is true
    if (location.state && location.state == "signupSuccess") {
      swal({
        title: "HINWEIS",
        icon: "info",
        text: "Wir haben ihnen eine E-Mail gesendet. Um ihre Anmeldung abzuschließen, klicken Sie auf den Bestätigungslink in der E-Mail",
        button: "OK",
      });
    }
  }, [location.state]);
  // Check if user is logged in
  useEffect(() => {
    const user = localStorage.getItem("currentuser");
    if (user) {
      // User is logged in, redirect immediately to /app/dashboard
      setLoader(true)
      history.push('/app/dashboard'); // Redirection with loading state
    }
  }, [history]);



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const fetchinfouser_company = async (user) => {


    const userDoc = await userService.getUser(user.user.uid);
    userDoc.forEach(async (doc) => {

      if (doc.data().company_id) {
        let Companydata = await companyService.getCompany(
          doc.data().company_id
        );
        if (!user.user.emailVerified) {
          swal({
            title: "ACHTUNG",
            icon: "info",
            text:
              "Ihre Anmeldung wurde nicht abgeschlossen. Bitte klicken Sie in der Bestätigungsemail auf den hinterlegten Link.",
            buttons: {
              cancel: "OK",
              resend: {
                text: "Authentifizierungslink neu versenden",
                value: "resend",
              },
            },
          }).then(async (value) => {
            if (value === "resend") {
              await userService.sendEmailVerification(formValues.email);
            }
          });

        }
        else if (doc.data().access == 0) {
          swal({
            title: "Wichtiger Hinweis",
            icon: "info",
            text: "Ihr Konto wurde gesperrt. Bitte kontaktieren Sie den X-CITE Admin oder Ihr zuständiges Maklerbüro.",
            button: "OK",
          });
        }
        else if (Companydata) {
          localStorage.setItem("currentuser", JSON.stringify(user._tokenResponse.idToken));
          localStorage.setItem('previousPath', '/login');
          setIsSubmit(true);
          window.location.href = redirectPath;
        }


      }
    });
  };

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setFormErrors(validate(formValues));
      setError("");
      setLoading(true);
      login(formValues.email, formValues.password).then(async user => {
        
        await fetchinfouser_company(user);
      })
        .catch((error) => {
          setError("Einloggen fehlgeschlagen");
        })
    } catch {
      setError("Einloggen fehlgeschlagen");
    }
    setLoading(false);
  };


  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.email) {
      errors.email = "Ungültige E-Mailadresse";
    } else if (!regex.test(values.email)) {
      errors.email = "Ungültige E-Mailadresse";
    }
    if (!values.password) {
      errors.password = "Ungültige Passwort";
    } else if (values.password.length < 6) {
      errors.password = "Das Passwort muss mindestens 6 Zeichen lang sein!";
    } else if (values.password.length > 12) {
      errors.password = "Das Passwort darf nicht länger als 12 Zeichen sein!";
    }
    return errors;
  };

  const handleRedirect = (url) => {
    window.open(url, "MyWindow", "width=700,height=500,top=100,left=100");
  };
  return (
    <>

      {loader === true ?
        <div id="loading-bg" className="hk-full-loader" >
          <div className="text-center">
            <Box mb={3}>

            </Box>
            <CircularProgress />
          </div>
        </div>
        : <div className="bg-color-home">
          <div className="margin-login container">
            <div className="row" >
              <div className="col-md-12">
                {Object.keys(formErrors).length === 0 && isSubmit ? (
                  <Alert severity="success">Ihre Anmeldung war erfolgreich.</Alert>
                ) : (
                  error && <Alert severity="error">{error}</Alert>
                )}
                <div className="centred-title pb-3 pt-3 ">
                  <h1 className="title-login">Einloggen</h1>
                </div>
                <div className="card pt-3 pb-3">
                  <div className="card-contain padding">
                    <span className="logo">
                      <img src={logoXcite} alt="logo" />
                    </span>
                    <Row className="mt-3 mb-3">
                      <Col md={12}>
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          flexDirection: 'column',
                        }}
                        >

                          <div className="input-field">
                            <img className="userIcon" src={userIcon} alt="user Icon" />
                            <TextField
                              name="email"
                              value={formValues.email}
                              onChange={handleChange}
                              id="email"
                              type="email"
                              inputRef={emailRef}
                              label="LOGIN"
                              placeholder="LOGIN *"
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              error={error}
                              required
                              InputProps={{
                                style: { paddingLeft: '35px' },
                              }}
                              variant="outlined" />
                          </div>
                          <span className="error">{formErrors.email}</span>


                          <div className="input-field">
                            <img className="passwordIcon" src={passwordIcon} alt="user Icon" />
                            <TextField
                              name="password"
                              id="password"
                              type={showPassword ? 'text' : 'password'}
                              inputRef={passwordRef}
                              label="PASSWORT"
                              placeholder="PASSWORT *"
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              required
                              error={error}
                              value={formValues.password}
                              onChange={handleChange}
                              InputProps={{
                                style: { paddingLeft: '35px' },
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                              variant="outlined"
                              className="input-field-PP"
                            />
                            <span className="error">{formErrors.password}</span>
                          </div>
                        </Box>
                      </Col>
                      <Col
                        md={12}
                        className="mt-4 mb-2"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Link className="forgetPass" to="/forgot-password">Passwort vergessen?</Link>


                        <Link className="forgetPass" to="/signup">Neu registrieren
                          <ArrowForwardIosIcon className="icon-registrieren" sx={{ fontSize: 20 }} />

                        </Link>

                      </Col>
                      <Col md={12} className="mt-2 mb-1">
                        <div className="Einloggen">
                          <button disabled={loading} className="LoginButton" type="submit" onClick={handleSubmit}>
                            Einloggen
                            <ArrowForwardIosIcon className="icon-registrieren" sx={{ fontSize: 20 }} />
                          </button>
                        </div>
                      </Col>
                      <Col md={12} className="mt-1 mb-1">
                        <div className="LoginAbout">
                          <img className="infoIcon" src={infoIcon} alt="user Icon" />
                          <Link to="#" onClick={() => handleRedirect("https://x-cite-app.de/")}>Erfahren Sie mehr über die X-CITE.immo App.</Link>
                        </div>
                      </Col>
                    </Row>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>

  )
}